<template>
	<v-layout fill-height justify-space-around column>
		<v-flex v-t="'application.application_partner'" shrink headline text-xs-center my-3></v-flex>
		<w-layout shrink align-center justify-center px-5 mx-1 mb-5 row wrap>
			<w-spacer />
			<w-btn
				v-if="hasApplicationCreationModule"
				icon="add"
				:mini="$vuetify.breakpoint.xsOnly"
				:small="$vuetify.breakpoint.xsOnly"
				@click="displayApplicationCreationDialog = true"
				>{{ $t('application.creation.button-title') }}</w-btn
			>
		</w-layout>
		<v-flex scroll-y px-5 mx-1 mb-5>
			<v-layout column>
				<UsedApplicationsTable />
				<UnusedApplicationsTable v-if="isAdmin" />
			</v-layout>
		</v-flex>
		<ApplicationCreationDialog v-model="displayApplicationCreationDialog" :service="service" />
	</v-layout>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'ApplicationsModule',
	components: {
		UnusedApplicationsTable: () => ({
			component: import('@/components/Applications/UnusedApplicationsTable')
		}),
		UsedApplicationsTable: () => ({
			component: import('@/components/Applications/UsedApplicationsTable')
		}),
		ApplicationCreationDialog: () => ({
			component: import('@/components/Applications/ApplicationCreationDialog')
		})
	},
	mixins: [ApplicationsModuleGuard],
	data: function () {
		return {
			displayApplicationCreationDialog: false
		}
	},
	computed: {
		hasApplicationCreationModule: function () {
			return this.appService.hasModule('create-applications')
		},
		isAdmin: function () {
			return this.selectedAccountingFirm?.isAccountantAdmin ?? false
		}
	}
}
</script>
