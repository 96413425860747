// ============================================================================
// ApplicationsApi
// -------------------
// Applications api
// ============================================================================

// -------
// Imports
// -------
import URLS from '@/apis/BackEndAPIEndpoints'
import { RequiredArguments } from '@/helpers/methods'
import { Backend, Configurator } from '@/plugins/axios/Configurator'
import { Config } from '@/services/ConfigService'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

// -------
// Exports
// -------

// INFO: This is a temporary state until a full refacto of this file is done
const API = {
	accountingFirm: {
		application: {
			parameters: {
				read: function (accountingFirmId, applicationId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId, applicationId })
					return Backend.GET(
						URLS.api.accountingFirms.applications.parameters.uri,
						[accountingFirmId, applicationId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				}
			},
			vendors: {
				create: function (accountingFirmId, applicationId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({accountingFirmId, applicationId})
					return Backend.POST(URLS.api.accountingFirms.applications.vendors.uri, [accountingFirmId, applicationId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
				}
			}
		},
		catalogTreeStructure: {
			read: function (accountingFirmId, catalogTreeStructureId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ accountingFirmId, catalogTreeStructureId })
				return Backend.GET(
					URLS.api.accountingFirms.catalogTreeStructures.uri,
					[accountingFirmId, catalogTreeStructureId],
					params,
					doCancelPreviousRequest,
					doCancelAllOtherRequests
				)
			},
			catalogFolder: {
				read: function (accountingFirmId, catalogTreeStructureId, catalogFolderId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId, catalogTreeStructureId, catalogFolderId })
					return Backend.GET(
						URLS.api.accountingFirms.catalogTreeStructures.catalogFolders.uri,
						[accountingFirmId, catalogTreeStructureId, catalogFolderId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				}
			},
			catalogFolders: {
				read: function (accountingFirmId, catalogTreeStructureId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
					RequiredArguments({ accountingFirmId, catalogTreeStructureId })
					return Backend.GET(
						URLS.api.accountingFirms.catalogTreeStructures.catalogFolders.uri,
						[accountingFirmId, catalogTreeStructureId],
						params,
						doCancelPreviousRequest,
						doCancelAllOtherRequests
					)
				}
			}
		},
		subscription: {
			vendors: {
				parameters: {
					read: function (accountingFirmId, subscriptionId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
						RequiredArguments({ accountingFirmId, subscriptionId })
						return Backend.GET(
							URLS.api.accountingFirms.subscriptions.vendors.parameters.uri,
							[accountingFirmId, subscriptionId],
							params,
							doCancelPreviousRequest,
							doCancelAllOtherRequests
						)
					}
				}
			},
			update: function (accountingFirmId, subscriptionId, data, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ accountingFirmId, subscriptionId })
				return Backend.PATCH(URLS.api.accountingFirms.subscriptions.uri, [accountingFirmId, subscriptionId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
		},
	}
}

const createApplicationVendor = (accountingFirmId, vendorId, catalogApplicationId, data) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/applications/${catalogApplicationId}/subscriptions`
	return axios.post(url, data)
}

const deleteApplicationVendor = (accountingFirmId, vendorId, catalogApplicationId, subscriptionId) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/applications/${catalogApplicationId}/subscriptions/${subscriptionId}`
	return axios.delete(url)
}

const modifyApplicationVendor = (accountingFirmId, vendorId, catalogApplicationId, subscriptionId, data) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/applications/${catalogApplicationId}/subscriptions/${subscriptionId}`
	return axios.patch(url, data)
}

const checkBeeyeToken = (accounting_firm_id, endpoint, token) => {
	const url = `/accounting-firms/${accounting_firm_id}/applications/beeye/token/check`
	return axios.post(url, {
		uri: endpoint,
		token: token
	})
}

const getBeeyeDirections = (accounting_firm_id, endpoint, token) => {
	const url = `/accounting-firms/${accounting_firm_id}/applications/beeye/directions`
	return axios.post(url, {
		uri: endpoint,
		token: token
	})
}

const getImportFile = (accountingFirmId, applicationId, params = {}) => {
	RequiredArguments({ accountingFirmId, applicationId })
	return fetch(`${Config.VUE_APP_ROOT_API}/api/accounting-firms/${accountingFirmId}/applications/${applicationId}/import_file?` + new URLSearchParams(params), {
		method: 'GET',
		params: params,
		headers: new Headers({
			'Accept-Encoding': 'gzip, deflate, br',
			Authorization: localStorage.getItem('token'),
			Host: Config.VUE_APP_ROOT_API
		}),
		mode: 'cors',
		cache: 'default',
		referrer: window.location,
		keepalive: false
	})
		.then(response => response.blob())
}


const listApplications = (accountingFirmId, params) => {
	const url = `/accounting-firms/${accountingFirmId}/applications`
	return axios.get(url, {
		params: params
	})
}

const getSubscription = (accountingFirmId, subscriptionId, params = {}, config = {}) => {
	const url = `/accounting-firms/${accountingFirmId}/subscriptions/${subscriptionId}`
	return axios.get(url, {...config, params})
}

const listMagicMailModels = () => {
	const url = '/magic-mail-models'
	return axios.get(url)
}

const createSubscription = (accountingFirmId, applicationId, params, config) => {
	const url = `/accounting-firms/${accountingFirmId}/applications/${applicationId}`
	return axios.post(url, params, config)
}

const deleteSubscription = (accountingFirmId, subscriptionId) => {
	const url = `/accounting-firms/${accountingFirmId}/subscriptions/${subscriptionId}`
	return axios.delete(url)
}

const listVendors = (accountingFirmId, applicationId, params) => {
	const url = `/accounting-firms/${accountingFirmId}/applications/${applicationId}/vendors`
	return axios.get(url, {params})
}

const createApplication = (data) => {
	const url = `/applications/`
	return axios.post(url, data)
}

const createApplicationUsers = (accountingFirmId, applicationId, data = {}, config = {}) => {
	const url = `/accounting-firms/${accountingFirmId}/applications/${applicationId}/users`
	return axios.post(url, data, config)
}

const updateApplication = (applicationId, data) => {
	const url = `/applications/${applicationId}`
	data.append('_method', 'PATCH')
	return axios.post(url, data)
}

export default {
	updateAccountingFirmApplication: API.accountingFirm.subscription.update,

	listAccountingFirmApplicationParameters: API.accountingFirm.application.parameters.read,
	listApplicationVendorParameters: API.accountingFirm.subscription.vendors.parameters.read,

	findCatalogFolder: API.accountingFirm.catalogTreeStructure.catalogFolder.read,
	getCatalogFolders: API.accountingFirm.catalogTreeStructure.catalogFolders.read,

	findCatalogTreeStructure: API.accountingFirm.catalogTreeStructure.read,
	
	getImportFile,
	listApplications,

	listMagicMailModels,

	getSubscription,
	createSubscription,
	deleteSubscription,

	createApplicationVendors: API.accountingFirm.application.vendors.create,
	listVendors,

	checkBeeyeToken: checkBeeyeToken,
	getBeeyeDirections: getBeeyeDirections,

	createApplicationVendor,
	modifyApplicationVendor,
	deleteApplicationVendor,

	createApplication,
	updateApplication,

	createApplicationUsers,
}
