import AbstractContextualizedModuleGuard from '@/mixins/ModulesGuards/AbstractContextualizedModuleGuard'
import Service from '@/services/Applications/ApplicationsService'
import { Events, Bus } from '@/events/Applications/ApplicationsEvents'

export default {
	mixins: [AbstractContextualizedModuleGuard],
	data: function () {
		return {
			moduleName: 'applications',
			service: Service,
			eventBus: Bus,
			events: Events
		}
	}
}
