import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	APPLICATION_UPDATED: 'application-updated',
	APPLICATION_SUBSCRIPTION_CREATED: 'application-subscription-created',
	APPLICATION_SUBSCRIPTION_UPDATED: 'application-subscription-updated',
	APPLICATION_SUBSCRIPTION_DELETED: 'application-subscription-deleted',
	APPLICATION_VENDOR_CREATED: 'application-vendor-created',
	APPLICATION_VENDOR_DELETED: 'application-vendor-deleted',
	APPLICATION_VENDOR_UPDATED: 'application-vendor-updated',
	OPEN_BEEYE: 'open-beeye',
	OPEN_JSE: 'open-jesignexpert',
	OPEN_AF_CONFIG: 'open-af-config',
	OPEN_DESCRIPTION: 'open-description',
	OPEN_VENDOR_DETAILS: 'open-vendor-details',
	OPEN_SUBSCRIBE: 'open-subscribe',
	UPDATE_APPLICATION_VENDOR: 'update-application-vendor'
})
